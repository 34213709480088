<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">BRANDS</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Add Brand</h4>
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Brand Name</label>
            <input
              v-model="brand.name"
              type="text"
              class="form-control"
              placeholder="Enter Brand Name"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Explore Brand Link</label>
            <input
              v-model="brand.brandPage"
              type="text"
              class="form-control"
              placeholder="Brand Link"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputPassword1">View Products Link</label>
            <input
              v-model="brand.productsPage"
              type="text"
              class="form-control"
              placeholder="Products Link"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Brand Description</label>
            <textarea
              v-model="brand.description"
              type="text"
              class="form-control"
              placeholder="Description"
            ></textarea>
          </div>
          <div>
            <img :src="imageUrl" class="img-fluid w-25 d-inline-block" />
          </div>
          <div class="form-group d-inline-block">
            <label for="exampleFormControlFile1">Brand Logo</label>
            <input
              @change="setImagePreview"
              ref="imageInput"
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
            />
          </div>

          <button @click.prevent="saveBrand" class="btn btn-block btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
export default {
  name: "AddBrand",
  data() {
    return {
      imageUrl: "/placeholder.png",
      brand: {
        name: "",
        logo: "",
        productsPage: "",
        brandPage: "",
        description: "",
      },
    };
  },
  methods: {
    async setImagePreview() {
      const fr = new FileReader();
      fr.onload = () => {
        this.imageUrl = fr.result;
      };
      console.log(this.$refs.imageInput.files[0]);
      const src = fr.readAsDataURL(this.$refs.imageInput.files[0]);
    },
    async saveBrand() {
      try {
        const fileName = await this.$store.dispatch(
          "uploadImage",
          this.$refs.imageInput.files[0]
        );

        this.brand.logo = fileName;

        const res = await axios.post("/api/v1/brands", this.brand, {
          headers: {
            authorization: `bearer ${this.$store.state.token}`,
          },
        });

        if (res.status == 201) {
          this.$router.push("/brands");
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style>